<template>
  <div class="readme-article">
    <h1 id="角色管理">八、角色管理</h1>
    <h2 id="角色列表">1.角色列表</h2>
    <p>
      查看角色列表，管理可新增、编辑、删除角色；切换角色可查看该角色下的所有成员信息。
    </p>
    <p>
      <img src="@/assets/img/scrm/8-1.png" />
    </p>
    <h2 id="角色权限">2.角色权限</h2>
    <p>
      切换上方权限范围可查看或编辑当前选中角色的权限设置。
    </p>
    <p>
      <img src="@/assets/img/scrm/8-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm8-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>